import clsx from 'clsx';
import dynamic from 'next/dynamic';
import React, { FC, HTMLAttributes, Suspense } from 'react';

import { Container } from '@/components/container';
import { Image } from '@/components/image';
import { menus, mockFlowerLarge, mockLists } from '@/components/layout/footer/footer.data';
import { PaymentParts } from '@/components/layout/footer/payment-parts/PaymentParts';

import { useMediaQuery } from '@/hooks/useMediaQuery';
import useSectionVisible from '@/hooks/useSectionVisible';

import styles from './Footer.module.scss';
import { BottomBar } from './bottom-bar/BottomBar';

const SocialBlock = dynamic(() => import('./social-block/SocialBlock'), {
  ssr: false,
  loading: () => <p>Загрузка социальных сетей...</p>,
});

const PaymentMethods = dynamic(() => import('./payment-methods/PaymentMethods'), {
  ssr: false,
  loading: () => <p>Загрузка методов оплаты...</p>,
});

const MenuList = dynamic(() => import('./menu-list/MenuList'));
const NavigationList = dynamic(() => import('./navigation-list/NavigationList'));

const Footer: FC<HTMLAttributes<HTMLDivElement>> = ({ className }) => {
  const isMobileDevice = useMediaQuery('768px');
  const [componentRef, sectionVisible] = useSectionVisible();
  return (
    <footer className={clsx(className)} ref={componentRef}>
      {sectionVisible && (
        <Container variant="footer">
          <div className={styles.mainGrid}>
            <div className={styles.logo}>
              <Image
                className={styles.logo}
                src={'/static/logo/azalianow-logo-red.svg'}
                priority
                width={184}
                height={84}
                alt="Логотип AzaliaNow"
              />
            </div>

            <div className={styles.menus}>
              <Suspense fallback={<div>Loading...</div>}>
                {menus.map((menu) => (
                  <MenuList
                    className={styles.menuBlock}
                    key={menu.title}
                    title={menu.title}
                    items={menu.items}
                    mode={isMobileDevice ? 'accordion' : 'default'}
                  />
                ))}
              </Suspense>
              <PaymentMethods className={clsx(styles.paymentMethodsBlock, styles.desktop)} />
              <PaymentParts className={clsx(styles.paymentPartsBlock, styles.desktop)} />
            </div>

            <SocialBlock className={styles.social} />
          </div>
        </Container>
      )}

      <Container variant="footer" className={styles.navigationLists}>
        <Suspense fallback={<div>Loading...</div>}>
          <div className={styles.navigationGrid}>
            {mockLists.map((list) => (
              <NavigationList
                key={list.title}
                href={list.href}
                title={list.title}
                items={list.items}
                mode={isMobileDevice && list.collapsable ? 'accordion' : 'default'}
              />
            ))}
          </div>
          <NavigationList
            variant="column"
            key={mockFlowerLarge.title}
            title={mockFlowerLarge.title}
            items={mockFlowerLarge.items}
            mode={isMobileDevice && mockFlowerLarge.collapsable ? 'accordion' : 'default'}
          />
        </Suspense>
      </Container>

      {isMobileDevice && (
        <Container variant="footer">
          <PaymentMethods
            isMobile={isMobileDevice}
            className={clsx(styles.paymentMethodsBlock, styles.mobile)}
          />
          <PaymentParts className={clsx(styles.paymentPartsBlock, styles.mobile)} />
        </Container>
      )}
      {sectionVisible && (
        <Container variant="footer" className={styles.bottomInformation}>
          <BottomBar />
        </Container>
      )}
    </footer>
  );
};
export default Footer;
