import { HTMLAttributeAnchorTarget, ReactNode } from 'react';

import { ROUTES } from '@/constants/routes.constant';

import AboutUsIcon from './assets/icons/about-us.svg';
import AzaliacoinIcon from './assets/icons/azaliacoin.svg';
import BlogIcon from './assets/icons/blog.svg';
import CorpCLientsIcon from './assets/icons/corp-clients.svg';
import DeliveryIcon from './assets/icons/delivery.svg';
import PaymentIcon from './assets/icons/payment.svg';
import PhoneIcon from './assets/icons/phone.svg';
import ReviewsIcon from './assets/icons/reviews.svg';
import SitemapIcon from './assets/icons/sitemap.svg';

type TActions = 'supportModal';

export type TMenuItem =
  | {
      title: string;
      href: string;
      target?: HTMLAttributeAnchorTarget;
      action?: never;
      iconName: string;
    }
  | {
      title: string;
      href?: never;
      target?: never;
      action: TActions;
      iconName: string;
    };

export type TMenu = {
  title: string;
  items: TMenuItem[];
};

export const menus: TMenu[] = [
  {
    title: 'О нас и контакты',
    items: [
      { title: 'О нас', href: ROUTES.ABOUT_US + '#footer', iconName: 'about-us' },
      { title: 'Контакты', href: ROUTES.CONTACTS + '#footer', iconName: 'phone' },
      // {
      //   title: 'Поддержка',
      //   icon: <SupportIcon />,
      //   action: 'supportModal',
      // },
    ],
  },
  {
    title: 'Обратная связь и материалы',
    items: [
      { title: 'Отзывы', href: ROUTES.REVIEWS + '#footer', iconName: 'reviews' },
      // { title: 'Вопрос - Ответ', href: ROUTES.CONTACTS, icon: <FaqIcon /> },
      {
        title: 'Блог',
        href: ROUTES.BLOG + '#footer',
        target: '_blank',
        iconName: 'blog',
      },
      { title: 'Карта сайта', href: ROUTES.SITEMAP + '#footer', iconName: 'sitemap' },
    ],
  },
  {
    title: 'Услуги и информация для клиентов',
    items: [
      { title: 'Доставка', href: ROUTES.DELIVERY + '#footer', iconName: 'delivery' },
      { title: 'Оплата', href: ROUTES.PAYMENT + '#footer', iconName: 'payment' },
      {
        title: 'Бонусная программа',
        href: ROUTES.BONUS_PROGRAM + '#footer',
        iconName: 'azaliacoin',
      },
      {
        title: 'Корпоративным клиентам',
        href: ROUTES.KORPORATIVNYM_KLIENTAM + '#footer',
        iconName: 'corp-clients',
      },
    ],
  },
];

export const mockLists = [
  {
    title: 'Купить букет',
    collapsable: true,
    items: [
      { title: 'Акции', href: `/rubric/akczii#footer` },
      { title: 'Хит продаж', href: '/rubric/hit-prodazh#footer' },
      { title: 'Букеты до 3000 р.', href: '/rubric/do-3-000-rublej#footer' },
      {
        title: 'Букеты от 3000 р. до 4000 р.',
        href: '/rubric/ot-3000-do-4000-rublej#footer',
      },
      {
        title: 'Букеты от 4000 р. до 6000 р.',
        href: '/rubric/ot-4-000-do-6-000-rublej#footer',
      },
      {
        title: 'Букеты от 6000 р. до 10 000 р.',
        href: '/rubric/ot-6000-rublej#footer',
      },
      {
        title: 'Букеты от 10 000 р. до 20 000 р.',
        href: '/rubric/ot-10-tysyach-rublej#footer',
      },
      { title: 'Премиум букеты', href: '/rubric/premium-bukety-ot-20-tysyach-rublej#footer' },
      { title: 'Корпоративным клиентам', href: '/rubric/korporativnym-klientam#footer' },
      { title: 'Букет из роз и гипсофил', href: '/rubric/rozy-i-gipsofily#footer' },
      { title: 'Букет из роз и хризантем', href: '/rubric/rozy-i-hrizantemy#footer' },
      { title: 'Букет из премиум роз', href: '/rubric/premium-rozy#footer' },
      { title: 'Букет из роз в крафте', href: '/rubric/rozy-v-krafte#footer' },
      { title: 'Букеты в корзинах', href: '/rubric/bukety-v-korzinah#footer' },
      { title: 'Букет из синих роз', href: '/rubric/sinie-rozy#footer' },
      { title: 'Розы в коробках', href: '/rubric/rozy-v-korobkah#footer' },
      { title: 'Огромные букеты', href: '/rubric/ogromnye#footer' },
      { title: 'Букеты в ящиках', href: '/rubric/bukety-v-yashhikah#footer' },
      { title: 'Классические букеты', href: '/rubric/klassicheskie#footer' },
      { title: 'Монобукеты', href: '/rubric/monobukety#footer' },
      { title: 'Авторские букеты', href: '/rubric/avtorskie#footer' },
      { title: 'Большие букеты', href: '/rubric/bolshie#footer' },
      { title: 'Экзотические букеты', href: '/rubric/ekzotika#footer' },
    ],
  },
  {
    title: 'Выбрать букет',
    collapsable: true,
    items: [
      { title: 'Для мамы', href: '/rubric/bukety-dlya-mam#footer' },
      { title: 'Для девочки', href: '/rubric/buket-devochke#footer' },
      { title: 'Для любимой', href: '/rubric/buket-lyubimoj#footer' },
      { title: 'Для бабушки', href: '/rubric/buket-babushke#footer' },
      { title: 'Для ребенка', href: '/rubric/buket-rebyonku#footer' },
      { title: 'Для мужчины', href: '/rubric/muzhchina-i-czvety#footer' },
      { title: 'В роддом', href: '/rubric/buket-v-roddom#footer' },
      { title: 'Для коллеги', href: '/rubric/buket-dlya-kollegi#footer' },
      { title: 'Для руководителя', href: '/rubric/buket-dlya-rukovoditelya#footer' },
    ],
  },
  {
    title: 'Заказать',
    collapsable: true,
    items: [
      { title: 'Букет на день рождения', href: '/rubric/buket-na-den-rozhdeniya#footer' },
      { title: 'Букет на рождение ребенка', href: '/rubric/buket-na-rozhdenie-rebenka#footer' },
      { title: 'Букет на последний звонок', href: '/rubric/buket-na-poslednij-zvonok#footer' },
      { title: 'Букет на выпускной', href: '/rubric/buket-na-vypusknoj#footer' },
      { title: 'Букет на свадьбу', href: '/rubric/buket-nevesty#footer' },
      { title: 'На торжество', href: '/rubric/bukety-na-torzhestvo#footer' },
      { title: 'Букет на Новый Год', href: '/rubric/novyj-god#footer' },
      { title: 'Букет на 14 февраля', href: '/rubric/buket-na-14-fevralya#footer' },
      { title: 'Букет на 23 февраля', href: '/rubric/buket-na-23-fevralya#footer' },
      { title: 'Букет на 8 марта', href: '/rubric/buket-na-8-marta#footer' },
      { title: 'Букет на 1 сентября', href: '/rubric/bukety-na-1-sentyabrya#footer' },
      { title: 'Букет на День учителя', href: '/rubric/buket-na-den-uchitelya#footer' },
      { title: 'Букет на День матери', href: '/rubric/bukety-na-den-materi#footer' },
    ],
  },
  {
    title: 'Горшечные растения',
    collapsable: true,
    items: [
      { title: 'Растения для офиса', href: '/rubric/rasteniya-dlya-ofisa#footer' },
      { title: 'Цветы для дома', href: '/rubric/cvety-dlya-doma#footer' },
      { title: 'Плотоядные растения', href: '/rubric/plotoyadnye-rasteniya#footer' },
      {
        title: 'Луковичные растения для сада',
        href: '/rubric/lukovichnye-rasteniya-dlya-sada#footer',
      },
    ],
  },
  {
    title: 'Декор',
    collapsable: true,
    items: [
      { title: 'Кашпо', href: '/rubric/kashpo#footer' },
      { title: 'Корзины', href: '/catalog/korziny#footer' },
      { title: 'Пасхальный декор', href: '/rubric/pasha#footer' },
      { title: 'Вазы', href: '/catalog/vazy#footer' },
      { title: 'Искусственные цветы', href: '/catalog/iskusstvennye-czvety#footer' },
      { title: 'Цветы для интерьера', href: '/rubric/czvety-dlya-interera#footer' },
    ],
  },
  {
    title: 'Траурные цветы',
    collapsable: true,
    items: [
      { title: 'Живые цветы на кладбище', href: '/rubric/pohoronnye-bukety#footer' },
      {
        title: 'Искусственные цветы на кладбище',
        href: '/rubric/iskusstvennye-cvety-na-kladbishe#footer',
      },
    ],
  },
  {
    title: 'Купить подарок',
    collapsable: true,
    items: [
      { title: 'Свечи', href: '/rubric/svechi#footer' },
      { title: 'Ароматы для дома', href: '/rubric/aromaty-dlya-doma#footer' },
      { title: 'Игрушки', href: '/rubric/igrushki#footer' },
    ],
  },
  {
    title: 'Инструкция по уходу за цветами',
    collapsable: false,
    href: 'https://azalianow.ru/blog/pyat-shagov-dlya-sohraneniya-buketa-v-domashnih-usloviyah',
    items: [
      {
        title: 'Уход за гортензией',
        href: 'https://azalianow.ru/blog/flower-shop/gortenziya-kak-sohranit-buket-na-dolgoe-vremya',
      },
      {
        title: 'Пионовидные розы. Руководство по выбору, уходу и покупке',
        href: 'https://azalianow.ru/blog/bouquet-of-roses/pionovidnye-rozy-rukovodstvo-po-vyboru-uhodu-i-pokupke',
      },
      {
        title: 'Как сохранить букет невесты после свадьбы: советы по уходу',
        href: 'https://azalianow.ru/blog/order-bouquet/kak-sohranit-buket-nevesty-posle-svadby-sovety-po-uhodu',
      },
      {
        title: 'Как купить букет пионов и не прогадать?',
        href: 'https://azalianow.ru/blog/choose-flowers/kak-kupit-buket-pionov-i-ne-progadat',
      },
      {
        title: 'Букеты с ранункулюсом, значение и уход',
        href: 'https://azalianow.ru/blog/amazing-plants/bukety-s-ranunkuljusom-znachenie-i-uhod',
      },
      {
        title: 'Долларовое дерево. Как ухаживать за замиокулькасом?',
        href: 'https://azalianow.ru/blog/amazing-plants/dollarovoe-derevo-kak-uhazhivat-za-zamiokulkasom',
      },
      {
        title: 'Пять шагов для сохранения букета в домашних условиях',
        href: 'https://azalianow.ru/blog/pyat-shagov-dlya-sohraneniya-buketa-v-domashnih-usloviyah',
      },
      {
        title: 'Орхидея – капризная красавица? Нет, просто не такая, как все!',
        href: 'https://azalianow.ru/blog/amazing-plants/orhideya-kapriznaya-krasavica-net-prosto-ne-takaya-kak-vse',
      },
      {
        title: 'Драцена – «ложная пальма». Как правильно за ней ухаживать?',
        href: 'https://azalianow.ru/blog/amazing-plants/dracena-lozhnaya-palma-kak-pravilno-za-nej-uhazhivat',
      },
    ],
  },
];

export const mockFlowerLarge = {
  title: 'Заказать цветы',
  collapsable: true,
  items: [
    { title: 'Агапантус', href: '/bukety/agapantus#footer' },
    { title: 'Аллиум', href: '/bukety/allium#footer' },
    { title: 'Альстромерия', href: '/bukety/alstromeriya#footer' },
    { title: 'Амариллис', href: '/bukety/amarillis#footer' },
    { title: 'Анемон', href: '/bukety/anemon#footer' },
    { title: 'Антуриум', href: '/bukety/anturium#footer' },
    { title: 'Астильба', href: '/bukety/astilba#footer' },
    { title: 'Астра', href: '/bukety/astra#footer' },
    { title: 'Астранция', href: '/bukety/astranciya#footer' },
    { title: 'Брассика', href: '/bukety/brassika#footer' },
    { title: 'Бувардия', href: '/bukety/buvardiya#footer' },
    { title: 'Ваксфловер (Хамелациум)', href: '/bukety/hamelacium#footer' },
    { title: 'Вероника кустовая', href: '/bukety/veronika-kustovaya#footer' },
    { title: 'Гвоздика (Диантус)', href: '/bukety/gvozdika#footer' },
    { title: 'Гвоздика кустовая', href: '/bukety/gvozdika-kustovaya#footer' },
    { title: 'Гелениум', href: '/bukety/gelenium#footer' },
    { title: 'Георгина', href: '/bukety/georgina#footer' },
    { title: 'Гербера', href: '/bukety/gerbera#footer' },
    { title: 'Гермини', href: '/bukety/germini#footer' },
    { title: 'Гиацинт', href: '/bukety/giacint#footer' },
    { title: 'Гипсофила', href: '/bukety/gipsofila#footer' },
    { title: 'Гладиолус', href: '/bukety/gladiolus#footer' },
    { title: 'Гортензия', href: '/bukety/gortenziya#footer' },
    { title: 'Дельфиниум', href: '/bukety/delfinium#footer' },
    { title: 'Ирис', href: '/bukety/iris#footer' },
    { title: 'Калла', href: '/bukety/kalla#footer' },
    { title: 'Картамус', href: '/bukety/kartamus#footer' },
    { title: 'Клематис', href: '/bukety/klematis#footer' },
    { title: 'Колокольчик (Кампанула)', href: '/bukety/kolokolchik#footer' },
    { title: 'Краспедия', href: '/bukety/kraspediya#footer' },
    { title: 'Кустовые розы', href: '/rubric/kustovye-rozy#footer' },
    { title: 'Левкадендрон', href: '/bukety/levkadendron#footer' },
    { title: 'Леукоспермум', href: '/bukety/leukospermum#footer' },
    { title: 'Лилия', href: '/bukety/liliya#footer' },
    { title: 'Лимониум (Статица)', href: '/bukety/statica#footer' },
    { title: 'Маттиола', href: '/bukety/mattiola#footer' },
    { title: 'Мимоза', href: '/bukety/mimoza#footer' },
    { title: 'Нарцисс', href: '/bukety/narciss#footer' },
    { title: 'Нерине', href: '/bukety/nerine#footer' },
    { title: 'Оксипеталум', href: '/bukety/oksipetalum#footer' },
    { title: 'Орнитогалум', href: '/bukety/ornitogalum#footer' },
    { title: 'Орхидея', href: '/bukety/orhideya#footer' },
    { title: 'Пион', href: '/bukety/pion#footer' },
    { title: 'Пионовидные розы', href: '/rubric/pionovidnye-rozy#footer' },
    { title: 'Подсолнух', href: '/bukety/podsolnuh#footer' },
    { title: 'Протея (Нутан)', href: '/bukety/proteya#footer' },
    { title: 'Ранункулюс', href: '/bukety/ranunkulyus#footer' },
    { title: 'Роза', href: '/bukety/roza#footer' },
    { title: 'Роза кустовая', href: '/bukety/roza-kustovaya#footer' },
    { title: 'Роза кустовая пионовидная', href: '/bukety/roza-kustovaya-pionovidnaya#footer' },
    { title: 'Роза пионовидная', href: '/bukety/roza-pionovidnaya#footer' },
    { title: 'Ромашка', href: '/bukety/romashka#footer' },
    { title: 'Седум', href: '/bukety/sedum#footer' },
    { title: 'Сирень', href: '/bukety/siren#footer' },
    { title: 'Сухоцвет', href: '/bukety/suhocvet#footer' },
    { title: 'Танацетум', href: '/bukety/tanacetum#footer' },
    { title: 'Тюльпан', href: '/bukety/tyulpan#footer' },
    { title: 'Флокс', href: '/bukety/floks#footer' },
    { title: 'Фрезия', href: '/bukety/freziya#footer' },
    { title: 'Хризантема', href: '/bukety/hrizantema#footer' },
    { title: 'Хризантема кустовая', href: '/bukety/hrizantema-kustovaya#footer' },
    { title: 'Целозия', href: '/bukety/czeloziya#footer' },
    { title: 'Эрингиум', href: '/bukety/eringium#footer' },
    { title: 'Эустома (Лизиантус)', href: '/bukety/eustoma#footer' },
  ],
};

// export const seoInfromation = {
//   title: 'Заказ букетов в режиме онлайн в нашем интернет-магазине цветов',
//   description: [
//     'AzaliaNow — масштабный развивающийся сервис на российском рынке онлайн-продаж и доставки цветов.',
//     'Купить цветы в Москве можно на каждом шагу. Но только у нас самые свежие срезанные цветы, ведь наш интернет-магазин букетов функционирует на базе крупнейшего оптового поставщика цветов в России. В нашем распоряжении склад площадью 50 тыс. кв. м. со свежими цветами с плантаций Кении, Эквадора, Голландии, России.',
//     'Наши дизайнеры-флористы создают эффектные букеты любой сложности. Уже сейчас вы можете выбрать из 7 тыс. букетов, и ассортимент постоянно пополняется. Мы гарантируем, что купленный букет будет в точности соответствовать тому, что размещено в карточке товара.',
//     'У нас доступна услуга «собрать букет по фотографии»: присылайте фото букета, и мы соберем вам точно такую композицию.',
//     'AzaliaNow — это современный удобный онлайн-сервис. Мы принимаем различные формы оплаты, предлагаем покупку цветов в рассрочку. За каждый букет на вашем счету добавляются бонусные баллы, которыми можно расплатиться за следующие заказы.',
//     'Бесплатная доставка осуществляется ежедневно с 06.00 до 00.00 ч. в выбранный вами 6-и часовой интервал. Для заказа букета день в день можно оформить доставку до 20:00, учитывая текущую загруженность. Для вашего удобства доступна экспресс-доставка, а также доставка точно ко времени.',
//     'Закажите идеальный букет всего за пару минут, используя удобную систему фильтров. Оформите заказ букетов с доставкой по Москве и области в несколько кликов и оцените нашу оперативность!',
//   ],
// };
