import clsx from 'clsx';

import { Image } from '@/components/image';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useTypedSelector } from '@/hooks/useTypedSelector';

import { switchModal } from '@/store/slices/individual-order-modal/individualOrderModal.slice';

import ModalWrapper from '../ui/modals/modal-wrapper/ModalWrapper';

import IndividualOrder from './IndividualOrder';
import styles from './IndividualOrder.module.scss';

function IndividualOrderPopupModal() {
  const { isOpen } = useTypedSelector((state) => state.individualOrderModal);
  const dispatch = useAppDispatch();

  function closeHandler() {
    dispatch(switchModal(false));
  }

  return (
    <ModalWrapper
      isOpen={isOpen}
      pt={{
        bottomSheet: {
          className: styles.modalContainer,
        },
      }}
      onClose={closeHandler}
    >
      <button className={clsx(styles.closeButton)} onClick={closeHandler}>
        <Image src={'/static/icons/close_icon.svg'} width={24} height={24} alt="Закрыть" />
      </button>
      <IndividualOrder />
    </ModalWrapper>
  );
}

export default IndividualOrderPopupModal;
