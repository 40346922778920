import clsx from 'clsx';
import dynamic from 'next/dynamic';
import React, { HTMLAttributes, createElement, useEffect, useRef, useState } from 'react';

import { Image } from '@/components/image';

import styles from './IndividualOrder.module.scss';

const IndividualOrderForm = dynamic(
  () => import('@/components/individual-order/IndividualOrderForm')
);

type Props = HTMLAttributes<HTMLDivElement> & {
  titleElement?: 'div' | 'h2';
  title?: string;
  description?: string;
};
const IndividualOrder = ({ className, titleElement = 'div', title, description }: Props) => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const componentRef = useRef(null);
  useEffect(() => {
    if ('IntersectionObserver' in window) {
      const observer = new IntersectionObserver(
        (entries, observerInstance) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setIsFormVisible(true);
              observerInstance.unobserve(entry.target);
            }
          });
        },
        {
          root: null,
          rootMargin: '200px',
          threshold: 0,
        }
      );

      if (componentRef.current) {
        observer.observe(componentRef.current);
      }

      return () => {
        if (componentRef.current) {
          observer.unobserve(componentRef.current);
        }
      };
    } else {
      setIsFormVisible(true);
    }
  }, []);

  const TitleNode = createElement(
    titleElement,
    {
      className: styles.title,
    },
    title || 'Соберем букет по фото'
  );

  return (
    <div className={clsx(styles.wrapper, className)} ref={componentRef}>
      <div className={styles.image}>
        <Image
          src="/static/images/individualorder.webp"
          fill
          loading="lazy"
          alt="Индивидуальный заказ"
          sizes="535px"
        />
      </div>
      <div className={styles.body}>
        <span className={styles.subTitle}>Пришлите картинку или фото</span>
        {TitleNode}
        <p className={styles.description}>
          {description ||
            'Просто пришлите нам букет, который вы нашли в интернете или в социальных сетях.'}
        </p>
        {isFormVisible && <IndividualOrderForm />}
      </div>
    </div>
  );
};

export default IndividualOrder;
