import clsx from 'clsx';
import Link from 'next/link';
import { HTMLAttributes } from 'react';

import { Image } from '@/components/image';

import { ROUTES } from '@/constants/routes.constant';

import styles from './BottomBar.module.scss';

type Props = HTMLAttributes<HTMLDivElement> & {};

const navigationLinks = [
  {
    title: 'Публичная оферта',
    href: ROUTES.PUBLIC_OFFER,
  },
  {
    title: 'Политика возвратов',
    href: ROUTES.RETURNS_POLICY,
  },
  {
    title: 'Пользовательское соглашение',
    href: ROUTES.USER_AGREEMENT,
  },
  {
    title: 'Политика конфиденциальности',
    href: ROUTES.PRIVACY_POLICY,
  },
  {
    title: 'Мы на ПромКод.ру',
    href: 'https://www.promkod.ru/shop/azalianow',
    target: '_blank',
  },
  {
    title: 'Mastodon',
    rel: 'me',
    href: 'https://mastodon.social/@azalianow',
    target: '_blank',
  },
];

const detailsOfTheLegalEntity = [
  'ООО ПИОНФЛО',
  'ИНН 7714462099',
  'ОГРН 1207700251670',
  'Г. МОСКВА, 1-Й МАГИСТРАЛЬНЫЙ ПРОЕЗД 12С1',
];

export const BottomBar = ({ className, ...rest }: Props) => {
  return (
    <div className={clsx(styles.wrapper, className)} {...rest}>
      <div className={styles.texts}>
        <nav className={styles.navigation}>
          {navigationLinks.map((el) => (
            <Link key={el.title} href={el.href} rel={el.rel} target={el.target}>
              {el.title}
            </Link>
          ))}
        </nav>
        <div className={styles.details}>
          {detailsOfTheLegalEntity.map((el) => (
            <span key={el}>{el}</span>
          ))}
        </div>
        <span className={styles.copyright}>
          2024 <span>AZALIANOW.RU</span> © ВСЕ ПРАВА ЗАЩИЩЕНЫ
        </span>
      </div>
      <Image
        src="/static/logo/azalianow-rounded-logo.svg"
        className={styles.logo}
        width={48}
        height={48}
        alt="Логотип AzaliaNow"
      />
    </div>
  );
};
